import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import styled from 'styled-components'

export class Breadcrumbs extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string,
      })
    ).isRequired,
  }
  render() {
    return (
      <BreadcrumbsContainer
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {this.props.items.map((item, i) => (
          <BreadcrumbsItem
            key={i}
            // className="breadcrumbs__item"
            itemProp="itemListElement"
            itemScope
            itemType="https://schema.org/ListItem"
          >
            {item.path ? (
              <NavLink
                exact
                to={item.path}
                // className="breadcrumbs__link"
                itemType="https://schema.org/Thing"
                itemProp="item"
              >
                <span itemProp="name">{item.title}</span>
              </NavLink>
            ) : (
              <span itemProp="name">{item.title}</span>
            )}
            <meta itemProp="position" content={i + 1} />
          </BreadcrumbsItem>
        ))}
      </BreadcrumbsContainer>
    )
  }
}

const BreadcrumbsContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-bottom: 12px;
`

const BreadcrumbsItem = styled.li`
  font-size: ${(p) => p.theme.fontSize.small};

  display: flex;
  flex-directoin: column;
  align-items: center;

  a {
    color: ${(p) => p.theme.colors.brand};
  }

  span {
    white-space: nowrap;
  }

  &:after {
    color: ${(p) => p.theme.colors.brand};
    content: '/';
    margin: 0 4px;
    font-size: 18px;
  }

  &:last-child {
    color: ${(p) => p.theme.colors.secondary};

    &:after {
      content: '';
    }
  }
`

export default withRouter(Breadcrumbs)
