"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.guardArray = exports.guardString = void 0;
var guardString = function (value, defaults) {
    if (defaults === void 0) { defaults = ''; }
    if (typeof value === 'string')
        return value;
    else
        return defaults;
};
exports.guardString = guardString;
var guardArray = function (value, defaults) {
    if (defaults === void 0) { defaults = []; }
    if (Array.isArray(value))
        return value;
    else
        return defaults;
};
exports.guardArray = guardArray;
