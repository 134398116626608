import axios from 'axios'
import {SAVE_ADMIN_SETUP, START, FAIL} from '../constants'
import {SUCCESS} from '../../../constants'

export const saveAdminSetup = (dispatch, action, data) => {
    dispatch({
        type: SAVE_ADMIN_SETUP + START,
    })

    axios({
        url: action,
        method: 'POST',
        data,
    })
        .then(({data}) => {
            if (data.status === 'ok') {
                dispatch({
                    type: SAVE_ADMIN_SETUP + SUCCESS,
                    response: data,
                })
            } else if (data.errors) {
                dispatch({
                    type: SAVE_ADMIN_SETUP + FAIL,
                    error: data.errors,
                })
            }
        })
        .catch((error) => {
            throw error
        })
}
